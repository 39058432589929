import React, { useEffect, useState } from "react";
import ModalCreateAI from "../components/modalCreateEmotion";
import ModalCreateEmotion from "../components/createEmotion";
import { TrashIcon, PencilIcon } from "@heroicons/react/outline";
import { showSuccessToast, showErrorToast } from "../utils/toast";
import http from "../services/http";

const Emotions = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpen1, setModalOpen1] = useState(false);
  const [emotions, setEmotions] = useState([]);

  const [modalTitle, setModalTitle] = useState("");
  const [data, setData] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [listOfEmotions, setlistOfEmotions] = useState([]);

  const getEmotions = () => {
    http
      .get("emotion/admin", {
        "Content-Type": "application/json",
      })
      .then((response) => {
        const data = response.data;
        console.log(data.data);
        setEmotions(data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getEmotions();
  }, [refresh]);

  const handleDeleteCharacter = (id) => {
    http
      .delete(`/emotion/delete/${id}`, {
        "Content-Type": "application/json",
      })
      .then((response) => {
        if (response.data.error === false) {
          showSuccessToast(response.data.message);
          setRefresh(!refresh);
        } else {
          showErrorToast("Error while deleting!");
          console.log(response.message);
        }
      });
  };

  useEffect(() => {
    try {
      http
        .get(`/emotion/allEmotions`, {
          "Content-Type": "application/json",
        })
        .then((response) => {
          if (response.data.error === false) {
            console.log(response.data.data);
            setlistOfEmotions(response.data.data);
          } else {
            console.log(response.data.message);
          }
        });
    } catch (error) {}
  }, [refresh]);
  return (
    <>
      {modalOpen && (
        <ModalCreateAI
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          modalTitle={modalTitle}
          data={data}
          refresh={refresh}
          setRefresh={setRefresh}
          emotionOptions={listOfEmotions}
        />
      )}
      {modalOpen1 && (
        <ModalCreateEmotion
          modalOpen1={modalOpen1}
          setModalOpen1={setModalOpen1}
          refresh={refresh}
          setRefresh={setRefresh}
        />
      )}

      <div className="px-6 lg:px-8">
        <div className="flex flex-row justify-between items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">
              Emotion List
            </h1>
            <p className="mt-2 text-sm text-gray-700">Create Emotion Panel</p>
          </div>
          <div className="sm:mt-0 sm:ml-16 sm:flex-none">
            <button
              type="button"
              className="block rounded-md bg-indigo-600 py-1.5 px-3 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={() => {
                setModalTitle("Create New AI Character");
                setData(null);
                setModalOpen(!modalOpen);
              }}
            >
              Create Attribute
            </button>
          </div>
          <div className="sm:mt-0 sm:ml-16 sm:flex-none">
            <button
              type="button"
              className="block rounded-md bg-indigo-600 py-1.5 px-3 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={() => {
                setModalOpen1(!modalOpen1);
              }}
            >
              Create Emotion
            </button>
          </div>
        </div>
        <div className="mt-8 flow-root">
          <div className="-my-2 -mx-6 overflow-x-auto lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-6 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3"
                    >
                      Emotion
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Attribute
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Value
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white">
                  {Object.entries(emotions).map(
                    ([emotionId, emotionData], index) => (
                      <React.Fragment key={index}>
                        {Object.keys(emotionData.attributes).length !== 0 ? (
                          Object.entries(emotionData.attributes).map(
                            ([attributeId, attributeData], attrIndex) => (
                              <tr
                                key={attrIndex}
                                className={
                                  (index *
                                    Object.keys(emotionData.attributes).length +
                                    attrIndex) %
                                    2 ===
                                  0
                                    ? undefined
                                    : "bg-gray-100"
                                }
                              >
                                {attrIndex === 0 ? (
                                  <td
                                    className="whitespace-nowrap py-2 pl-6 pr-3 text-sm font-medium text-gray-900 sm:pl-3"
                                    rowSpan={
                                      Object.keys(emotionData.attributes).length
                                    }
                                  >
                                    {emotionData.emotion}
                                  </td>
                                ) : null}
                                <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                                  {attributeData.attribute}
                                </td>
                                <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                                  {attributeData.value}
                                </td>
                                <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                                  <div className="flex flex-row justify-around items-center">
                                    <button
                                      onClick={() => {
                                        setModalTitle("Edit Emotion Attribute");
                                        setData({
                                          emotionId,
                                          attributeId,
                                          emotionName: emotionData.emotion,
                                          attribute: attributeData.attribute,
                                          value: attributeData.value,
                                        });
                                        setModalOpen(!modalOpen);
                                      }}
                                    >
                                      <PencilIcon className="h-6 w-6" />
                                    </button>
                                    <button
                                      onClick={() =>
                                        handleDeleteCharacter(attributeId)
                                      }
                                    >
                                      <TrashIcon className="h-6 w-6 text-red-500" />
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            )
                          )
                        ) : (
                          // Render a row with just the emotion name if there are no attributes
                          <tr>
                            <td
                              className="whitespace-nowrap py-2 pl-6 pr-3 text-sm font-medium text-gray-900 sm:pl-3"
                              rowSpan={1}
                            >
                              {emotionData.emotion}
                            </td>
                            <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500"></td>
                            <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500"></td>
                            <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                              <div className="flex flex-row justify-around items-center">
                                <button
                                  onClick={() => {
                                    setModalTitle("Edit Emotion Attribute");
                                    setData({
                                      emotionId,
                                      attributeId: null,
                                      emotionName: emotionData.emotion,
                                      attribute: "",
                                      value: "",
                                    });
                                    setModalOpen(!modalOpen);
                                  }}
                                >
                                  <PencilIcon className="h-6 w-6" />
                                </button>
                                {/* You can handle delete for the entire emotion here */}
                                <button
                                  onClick={() =>
                                    handleDeleteCharacter(emotionId)
                                  }
                                >
                                  <TrashIcon className="h-6 w-6 text-red-500" />
                                </button>
                              </div>
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    )
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Emotions;
