import React, { useEffect, useState } from "react";
import ModalCreateAI from "../components/modalCreateAI";
import { TrashIcon, PencilIcon } from "@heroicons/react/outline";
import http from "../services/http";
import { Space, Spin } from "antd";
// import copy from "../assets/copy.png";
import { showErrorToast, showSuccessToast } from "../utils/toast";

const CharacterTable = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [characters, setChracters] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [modalTitle, setModalTitle] = useState("");
  const [data, setData] = useState(null);
  const [refresh, setRefresh] = useState(false);

  let widgetTemplate = (url, backgroundColor) => {
    const template = ` <style>#chatbot-chat{border:0;background-color:transparent;z-index:2147483639;position:fixed;right:5px;width:410px;height:80vh;opacity:1;max-width:100%;bottom:70px;max-height:100%;box-shadow:0 25px 50px -12px rgba(0,0,0,.25);display:none}.cross-header{cursor:none;background-color:${backgroundColor};position:absolute;top:-2rem;right:0;z-index:1;height:3rem;width:25.62rem;border-radius:10px 10px 0 0;display:none}.closeButton{cursor:pointer;display:none;position:absolute;top:-3px;right:-4px;padding:5px;width:37px;z-index:9147483639}#chatbot-chat-frame{pointer-events:all;background:0 0;display:none;border:0;float:none;position:absolute;inset:0;width:100%;height:100%;margin:0;padding:0;min-height:0;border-radius:10px}.showButton{pointer-events:all;padding:4px;border-radius:12px;cursor:pointer}.button-wrapper{position:fixed;bottom:13px;right:14px;background-color:${backgroundColor};border-radius:50%;box-shadow:2px 2px 10px 2px rgba(.5,1,1,1)}.chat-container{position:relative}.ico{width:25px;background:${backgroundColor};height:25px;padding:5px}.icons{height:33px!important;width:40px!important;padding:16px!important;border-radius:29px}.fade{opacity:0;animation:fadeIn .3s forwards}.fades{opacity:0;animation:fadeout .2s backwards}@keyframes fadeIn{from{opacity:0}to{opacity:1}}@keyframes fadeout{from{opacity:1}to{opacity:0}}</style>
<body>
<div class=chat-container>
<div id=chatbot-chat>
<div class=cross-header>
<a class=closeButton onclick=closeWidget()><img class=ico src=https://sunrisedatabucket.s3.amazonaws.com/inworld/icons8-close-48-1704782426645.png alt=""></a>
</div>
<div id=chatbot-chat-frame-container>
<iframe src=${url} name=chat-widget id=chatbot-chat-frame title="ChatBot chat widget"></iframe>
</div>
</div>
<div class=button-wrapper>
<a class=showButton onclick=showWidget()><img class="ico icons" src=https://sunrisedatabucket.s3.amazonaws.com/inworld/chat-1704789947668.png alt=Icon></a>
</div>
</div>
<script>let widgetDiv=document.querySelector("#chatbot-chat-frame"),showButton=document.querySelector(".showButton"),closeButton=document.querySelector(".closeButton"),chatBot=document.querySelector("#chatbot-chat"),header=document.querySelector(".cross-header"),chatbotChatFrameContainer=document.querySelector("#chatbot-chat-frame-container");showWidget=()=>{showButton.style.display="none",closeButton.style.display="block",widgetDiv.style.display="block",chatBot.style.display="block",header.style.display="block",chatBot.style.bottom="2px",chatBot.style.right="1px",chatbotChatFrameContainer.classList.add("fade"),chatbotChatFrameContainer.classList.remove("fades"),header.classList.add("fade"),header.classList.remove("fades")},closeWidget=()=>{setTimeout((()=>{showButton.style.display="block",closeButton.style.display="none",widgetDiv.style.display="none",chatBot.style.display="none",header.style.display="none"}),100),chatbotChatFrameContainer.classList.add("fades"),chatbotChatFrameContainer.classList.remove("fade")}</script>
</body>`;
    return template;
  };

  let copyWidget = (key, user, backgroundColor) => {
    let bypassUrl = `https://chat.campusxr.org/${key}?name=${user}`;
    let finalTemplate = widgetTemplate(bypassUrl, backgroundColor);
    navigator.clipboard
      .writeText(finalTemplate)
      .then(() => {
        showSuccessToast("Text copied to clipboard");
      })
      .catch((error) => {
        showErrorToast("Error copying text to clipboard:");
      });
  };

  const getChracters = () => {
    http
      .get("character", {
        "Content-Type": "application/json",
      })
      .then((response) => {
        const data = response.data;
        setChracters(data.data);
      })
      .catch((error) => {
        console.log(error);
        setShowLoader(false);
      })
      .finally(() => {
        setShowLoader(false);
      });
  };

  useEffect(() => {
    getChracters();
  }, [refresh]);

  //  Delete Character
  const handleDeleteCharacter = (id) => {
    http
      .delete(`/character/${id}`, {
        "Content-Type": "application/json",
      })
      .then((response) => {
        if (response.data.error === false) {
          showSuccessToast("Character deleted.");
          setRefresh(!refresh);
          // setTempVar(!tempVar);
        } else {
          showErrorToast("Error while deleting!");
          console.log(response.message);
        }
      });
  };

  return (
    <>
      {showLoader ? (
        <div className="loader h-screen  grid place-content-center bg-white">
          <Space size="middle">
            <Spin size="large" />
          </Space>
        </div>
      ) : (
        <div>
          {modalOpen && (
            <ModalCreateAI
              modalOpen={modalOpen}
              setModalOpen={setModalOpen}
              modalTitle={modalTitle}
              data={data}
              refresh={refresh}
              setRefresh={setRefresh}
            />
          )}

          <div className="px-6 lg:px-8">
            <div className="flex flex-row justify-between items-center">
              <div className="sm:flex-auto">
                <h1 className="text-xl font-semibold text-gray-900">AI List</h1>
                <p className="mt-2 text-sm text-gray-700">
                  Create AI Character Panel
                </p>
              </div>
              <div className="sm:mt-0 sm:ml-16 sm:flex-none">
                <button
                  type="button"
                  className="block rounded-md bg-indigo-600 py-1.5 px-3 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  onClick={() => {
                    setModalTitle("Create New AI Character");
                    setData(null);
                    setModalOpen(!modalOpen);
                  }}
                >
                  Create AI
                </button>
              </div>
            </div>
            <div className="mt-8 flow-root">
              <div className="-my-2 -mx-6 overflow-x-auto lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-6 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3 sticky"
                        >
                          Key
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 hidden"
                        >
                          Character Name
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 hidden"
                        >
                          Scene Name
                        </th>

                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Bypass URL
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          URL
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Logo
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Video
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left w-32 text-sm font-semibold text-gray-900"
                        >
                          Text Color
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Character Color
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Gradient Color
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Avatar Background
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Text
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Chat View
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Chat History
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Language
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Chat Bar
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Gender
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Voice
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Voice Name
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900"
                        >
                          Idle Start Time
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900"
                        >
                          Idle End Time
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900"
                        >
                          Movement Start Time
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900"
                        >
                          Is Muted
                        </th>

                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Copy Widget
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900"
                        >
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white">
                      {characters.map((character, index) => (
                        <tr
                          key={index}
                          className={
                            index % 2 === 0 ? undefined : "bg-gray-100"
                          }
                        >
                          <td className="whitespace-nowrap py-2 pl-6 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
                            {character.key || "-"}
                          </td>
                          <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500 hidden">
                            {character.characterName || "-"}
                          </td>
                          <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500 hidden">
                            {character.sceneName || "-"}
                          </td>
                          <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                            https://chat.campusxr.org/{character.key}?name=
                            {character.userName}
                          </td>
                          <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                            https://chat.campusxr.org/{character.key}
                          </td>
                          <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                            <img
                              src={character.logo}
                              alt=""
                              className="h-10 w-10"
                            />
                          </td>
                          <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                            {character.chat_view === "Video" && (
                              <video
                                src={character.video}
                                alt=""
                                className="h-10 w-10"
                              />
                            )}
                          </td>
                          <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                            {character.textColor || "-"}
                          </td>
                          <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                            {character.characterColor || "-"}
                          </td>
                          <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                            {character.gradientColor || "-"}
                          </td>
                          <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                            {character.backgroundColor || "-"}
                          </td>

                          <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                            {character.text || "-"}
                          </td>
                          <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                            {character.chat_view || "-"}
                          </td>
                          <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                            {character.chat_history ? "Show" : "Don't Show"}
                          </td>
                          <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                            {character.language && character.language !== "null"
                              ? character.language
                              : "-"}
                          </td>
                          <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                            {character.chat_bar ? "Show" : "Don't Show"}
                          </td>
                          <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                            {character.gender || "-"}
                          </td>
                          <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                            {character.voice || "-"}
                          </td>
                          <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                            {character.voiceName || "-"}
                          </td>
                          <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                            {character.chat_view === "Video"
                              ? `${character.idleStartTime} sec`
                              : "-"}
                          </td>
                          <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                            {character.chat_view === "Video"
                              ? `${character.idleEndTime} sec`
                              : "-"}
                          </td>
                          <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500 text-center">
                            {character.movementStartTime &&
                            character.chat_view === "Video" ? (
                              JSON.parse(character.movementStartTime).map(
                                (time, index, array) => (
                                  <span key={index}>
                                    {time} sec
                                    {index < array.length - 1 && ", "}
                                  </span>
                                )
                              )
                            ) : (
                              <span className="text-center">-</span>
                            )}
                          </td>

                          <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                            {character.isMuted ? "Muted" : "Unmuted"}
                          </td>
                          <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                            <img
                              alt=""
                              src="copy.png"
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                copyWidget(
                                  character.key,
                                  character.userName,
                                  character.backgroundColor
                                )
                              }
                              width="25px"
                            />
                          </td>
                          <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                            <div className="flex flex-row justify-around items-center">
                              <button
                                onClick={() => {
                                  setModalTitle("Update AI Character");
                                  setData(character);
                                  setModalOpen(!modalOpen);
                                }}
                              >
                                <PencilIcon className="h-6 w-6" />
                              </button>
                              <button
                                onClick={() => {
                                  handleDeleteCharacter(character.id);
                                }}
                              >
                                <TrashIcon className="h-6 w-6 text-red-500" />
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CharacterTable;
