import React, { useState } from "react";

const Tooltip = ({ text, children }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div
      className="relative group"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}
      {isHovered && (
        <div className="bg-gray-800 text-white text-sm p-2 rounded absolute bottom-full left-1/2 transform -translate-x-1/2 opacity-100 transition-opacity duration-300">
          {text}
        </div>
      )}
    </div>
  );
};

export default Tooltip;
