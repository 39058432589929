// import "./App.less";
import "./style.css";
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Widget from "./pages/Widget";
import CreateWidget from "./pages/CreateWidget";
import { Space, Spin } from "antd";
import { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { isLoggedIn } from "./utils/common";
import "antd/dist/antd.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Dashboard from "./pages/dashboard";
import Emotions from "./pages/emotions";
import Characters from "./pages/characters";
import SoulMachines from "./pages/soulMachines";
import Dialogue from "./pages/dialogue";
// import Contact from "./pages/Contact";
function App() {
  const [loader, setLoader] = useState(false);
  const [LoggedIn, setLogin] = useState(false);

  useEffect(() => {
    isLoggedIn(setLogin);
  }, []);
  return (
    <div className="app">
      {loader ? (
        <div className="loader">
          <Space size="middle">
            <Spin size="large" />
          </Space>
        </div>
      ) : (
        <Router>
          <Routes>
            {LoggedIn ? (
              <>
                {/* <Route
                  path="/createcharacter"
                  element={
                    <CreateWidget loader={setLoader} setLogin={setLogin} />
                  }
                />
                <Route
                  path="/character"
                  element={<Widget loader={setLoader} setLogin={setLogin} />}
                /> */}
                {/* <Route
                  exact
                  path="/"
                  element={<CreateWidget loader={setLoader} />}
                /> */}
                <Route path="/" element={<Dashboard setLogin={setLogin} />}>
                  <Route
                    exact
                    path="/"
                    // element={<Dashboard setLogin={setLogin} />}
                    element={
                      <Characters setLoader={setLoader} loader={loader} />
                    }
                  />{" "}
                  <Route exact path="/emotions" element={<Emotions />} />
                  <Route
                    exact
                    path="/soulMachines"
                    element={
                      <SoulMachines setLoader={setLoader} loader={loader} />
                    }
                  />
                  <Route
                    exact
                    path="/dialog"
                    element={<Dialogue setLoader={setLoader} loader={loader} />}
                  />
                </Route>
              </>
            ) : (
              <>
                {/* <Route
                  path="/createcharacter"
                  element={<Home login={setLogin} />}
                />
                <Route path="/character" element={<Home login={setLogin} />} /> */}
                <Route exact path="/" element={<Home login={setLogin} />} />
              </>
            )}
          </Routes>
        </Router>
      )}
      <ToastContainer />
    </div>
  );
}

export default App;
