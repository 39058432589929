import React, { Fragment, useRef, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import http from "../services/http";
import { showSuccessToast, showErrorToast } from "../utils/toast";
export default function ModalCreateEmotionName({
  modalOpen1,
  setModalOpen1,
  refresh,
  setRefresh,
}) {
  const emotionAttrRef = useRef(null);

  const [open, setOpen] = useState(modalOpen1);
  const saveHandler = (e) => {
    e.preventDefault();
    let body = new Object({
      emotionName: emotionAttrRef.current.value,
    });
    try {
      http
        .put(`/emotion/createEmotion`, body, {
          "Content-Type": "application/json",
        })
        .then((response) => {
          console.log(response);
          if (response.status === 201) {
            showSuccessToast("Emotion added.");
            setRefresh(!refresh);
          } else {
            showErrorToast("Some error occured!");
            console.log(response.message);
          }
        });
    } catch (error) {}
    closeModal();
  };
  const resetRefs = () => {
    emotionAttrRef.current = "";
  };

  const closeModal = () => {
    resetRefs();
    setModalOpen1(false);
    setOpen(false);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all md:max-w-lg sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  <div className="mt-2 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Create Emotion
                    </Dialog.Title>
                    <form className="mt-3 text-left" onSubmit={saveHandler}>
                      <div className="mt-6 grid grid-cols-2 gap-y-6 gap-x-4 sm:grid-cols-6">
                        {/* Character Name Input */}
                        <div className="sm:col-span-6">
                          <label
                            htmlFor="char-name"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Emotion Name
                          </label>
                          <div className="mt-1">
                            <input
                              ref={emotionAttrRef}
                              defaultValue={emotionAttrRef.current}
                              onChange={() => {}}
                              type="text"
                              name="char-name"
                              id="char-name"
                              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            />
                          </div>
                        </div>

                        <div className="mt-3">
                          <div className="flex flex-row justify-end">
                            <button
                              onClick={(e) => saveHandler(e)}
                              type="submit"
                              className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
