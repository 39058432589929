import React, { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";

import {
  HomeIcon,
  XIcon,
  ArrowCircleLeftIcon,
  FolderIcon,
  MailIcon,
  CreditCardIcon,
} from "@heroicons/react/solid";
import { useNavigate } from "react-router-dom";
const navigation = [
  { name: "Inworld", href: "/", icon: HomeIcon, current: true },
  { name: "Emotion", href: "/emotions", icon: FolderIcon, current: true },
  {
    name: "Soul Machines",
    href: "/soulMachines",
    icon: MailIcon,
    current: true,
  },
  {
    name: "DialogFlow",
    href: "/dialog",
    icon: CreditCardIcon,
    current: true,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Sidebar = ({ isOpen, setIsOpen, setLogin }) => {
  const navigate = useNavigate();

  const handleClose = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem("user");
    setLogin(false);
    navigate("/");
  };
  return (
    <>
      <Transition.Root show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-40 md:hidden"
          onClose={handleClose}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-gray-800">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={handleClose}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                <div className="h-0 flex-1 overflow-y-auto pt-5 pb-4">
                  {/* <div className="flex flex-shrink-0 items-center px-4">
                    <img
                      className="h-8 w-auto"
                      src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500"
                      alt="Your Company"
                    />
                  </div> */}
                  <button onClick={handleLogout}>Logout</button>
                  <nav className="mt-5 space-y-1 px-2">
                    {navigation.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className={classNames(
                          item.current
                            ? "bg-gray-900 text-white"
                            : "text-gray-300 hover:bg-gray-700 hover:text-white",
                          "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                        )}
                      >
                        <item.icon
                          className={classNames(
                            item.current
                              ? "text-gray-300"
                              : "text-gray-400 group-hover:text-gray-300",
                            "mr-4 flex-shrink-0 h-6 w-6"
                          )}
                          aria-hidden="true"
                        />
                        {item.name}
                      </a>
                    ))}
                  </nav>
                </div>
                <div className="flex flex-shrink-0 bg-red-500 p-4 items-center">
                  <ArrowCircleLeftIcon className="h-6 w-6 mr-3 text-white" />
                  <button onClick={handleLogout} className="">
                    Logout
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
            <div className="w-14 flex-shrink-0">
              {/* Force sidebar to shrink to fit close icon */}
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex min-h-0 flex-1 flex-col bg-black">
          <div className="flex flex-1 flex-col overflow-y-auto pt-5 pb-4">
            <div className="flex flex-shrink-0 items-center px-4">
              <img
                className="w-full"
                src={require("../assets/logoTemp.png")}
                alt="Your Company"
              />
            </div>
            <nav className="mt-5 flex-1 space-y-1 px-2">
              {navigation.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className={classNames(
                    item.current
                      ? "bg-gray-900 text-white"
                      : "text-gray-300 hover:bg-gray-700 hover:text-white",
                    "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                  )}
                >
                  <item.icon
                    className={classNames(
                      item.current
                        ? "text-gray-300"
                        : "text-gray-400 group-hover:text-gray-300",
                      "mr-3 flex-shrink-0 h-6 w-6"
                    )}
                    aria-hidden="true"
                  />
                  {item.name}
                </a>
              ))}
            </nav>
          </div>
          <div className="flex flex-shrink-0 bg-red-500 p-4 items-center">
            {/* <a href="#" className="group block w-full flex-shrink-0">
              <div className="flex items-center">
                <div>
                  <img
                    className="inline-block h-9 w-9 rounded-full"
                    src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                    alt=""
                  />
                </div>
                <div className="ml-3">
                  <p className="text-sm font-medium text-white">Tom Cook</p>
                  <p className="text-xs font-medium text-gray-300 group-hover:text-gray-200">
                    View profile
                  </p>
                </div>
              </div>
            </a> */}
            <ArrowCircleLeftIcon className="h-6 w-6 mr-3 text-white" />
            <button
              onClick={handleLogout}
              className="text-white justify-between"
            >
              Logout
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;

// import Header from "./Header";
// import React from "react";
// import { Nav } from "react-bootstrap";
// import { useNavigate } from "react-router-dom";
// import { Button, PageHeader } from "antd";
// import LogoImg from "../assets/logoTemp.png";
// import AvatarIcon from "../assets/avatar-icon.png";
// import "../pages/Sidebar.css";

// const Sidebar = ({ setLogin,back }) => {
//     const navigate = useNavigate();
//   return (
//     <>
//       <Nav
//         className="col-md-2 d-none d-md-block bg-light sidebar"
//         activeKey="link-1"
//       >
//         <div className="sidebar-sticky"></div>
//         <div className="imgLogo">
//           <img className="logo" src={LogoImg} />
//         </div>
//         {/* <Nav.Item>
//           <Nav.Link eventKey="link-1">User</Nav.Link>
//         </Nav.Item> */}
//         <Nav.Item className="subHeadings">
//           <img src={AvatarIcon} style={{width:"40px"}}/>
//           <Nav.Link eventKey="link-2" style={{fontSize:"25px"}}>Characters</Nav.Link>
//         </Nav.Item>
//         <div className="logoutFooter">
//           {back ? (
//             <>
//               {" "}
//               <Button
//                 key="1"
//                 // type="primary"
//                 className="logoutBtn"
//                 onClick={() => {
//                   localStorage.removeItem("user");
//                   setLogin(false);
//                   navigate("/");
//                 }}
//                 danger
//               >
//                 Log out
//               </Button>
//             </>
//           ) : (
//             <>
//               {" "}
//               <Button
//                 key="1"
//                 // type="primary"
//                 className="logoutBtn"
//                 onClick={() => {
//                   localStorage.removeItem("user");
//                   setLogin(false);
//                   navigate("/");
//                 }}
//                 danger
//               >
//                 Log out
//               </Button>
//             </>
//           )}
//         </div>
//       </Nav>
//     </>
//   );
// };
// export default Sidebar;
