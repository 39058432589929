import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Form, Card } from "react-bootstrap";
// import logo from "../assets/health-coach-slim.jpg";
import logoTemp from "../assets/logoTemp.png";
import "./home.css";
import { showSuccessToast, showErrorToast } from "../utils/toast";
import { isLoggedIn } from "../utils/common";
import http from "../services/http";

function Home({ login }) {
  const navigate = useNavigate();
  const [password, setPassword] = useState();
  const [username, setUsername] = useState();
  const checkPassword = async (e) => {
    http
      .post(
        `/user/login`,
        {
          userName: username,
          userPassword: password,
        },
        {
          "Content-Type": "application/json",
        }
      )
      .then((response) => {
        console.log(response);
        localStorage.setItem("user", "loggedin");
        login(true);
        navigate("/");
        // window.location.reload();
        showSuccessToast("Logged in.");
      })
      .catch(function (error) {
        console.error(error);
        showErrorToast("Incorrect username or password.");
      });
  };
  return (
    <div className="text-center mainDiv">
      <Card className="loginCard">
        <div className="imgDiv">
          <Card.Img variant="top" src={logoTemp} className="loginImg" />
          <p className="leftDivText">
           CampusXR Admin panel
          </p>
        </div>
        <Card.Body className="cardBody">
          <p className="welcomeTag">Welcome Back</p>
          <Card.Title className="adminPanelHeading">ADMIN PANEL</Card.Title>
          <Form className=" d-inline" size="sm">
            <Form.Group
              className="mb-3"
              controlId="formBasicPassword"
              style={{ width: "75%", margin: "auto" }}
            >
              <Form.Control
                type="text"
                placeholder="Username"
                className="inputFields"
                onChange={(e) => setUsername(e.target.value)}
              />
              <Form.Control
                type="password"
                placeholder="Password"
                className="inputFields"
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Group>
          </Form>
          <Button className="loginBtn" onClick={checkPassword}>
            submit
          </Button>
        </Card.Body>
      </Card>
    </div>
  );
}

export default Home;
