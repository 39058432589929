import React, { useState } from "react";
import { MenuIcon } from "@heroicons/react/solid";

import Sidebar from "../components/Sidebar";
import Characters from "./characters";
import { Outlet } from "react-router-dom";

export default function Dashboard({ setLogin }) {
  const [openSideBar, setOpenSideBar] = useState(false);
  return (
    <>
      <div>
        <Sidebar
          isOpen={openSideBar}
          setIsOpen={setOpenSideBar}
          setLogin={setLogin}
        />
        <div className="flex flex-1 flex-col md:pl-64">
          <div className="sticky top-0 z-10 bg-gray-100 pl-1 pt-1 sm:pl-3 sm:pt-3 md:hidden">
            <button
              type="button"
              className="-ml-0.5 -mt-0.5 inline-flex h-12 w-12 items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
              onClick={() => setOpenSideBar(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <MenuIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <main className="flex-1">
            <div className="py-6">
              <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <h1 className="text-2xl font-semibold text-gray-900">&nbsp;</h1>
              </div>
              <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
                {/* Replace with your content */}
                {/* <Characters/> */}
                <Outlet />
                {/* /End replace */}
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
