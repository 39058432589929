import React, { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { TrashIcon, PencilIcon } from "@heroicons/react/outline";
import http from "../services/http";
import { showSuccessToast, showErrorToast } from "../utils/toast";
import Tooltip from "./ToolTip";

export default function SoulModal({
  data,
  modalOpen,
  setModalOpen,
  modalTitle,
  refresh,
  setRefresh,
  setShowLoader,
}) {
  const [open, setOpen] = useState(modalOpen);
  const [key, setKey] = useState("");
  const [characterName, setCharacterName] = useState("");
  const [projectKey, setProjectKey] = useState("");
  const [logo, setLogo] = useState(null);
  const [text, setText] = useState("");
  const [video, setVideo] = useState(null);
  const [videoPreviewURL, setVideoPreviewURL] = useState(null);
  const [logoPreview, setLogoPreview] = useState(null);
  const [videoDuration, setVideoDuration] = useState(null);
  const [idleStartTime, setIdleStartTime] = useState("");
  const [idleEndTime, setIdleEndTime] = useState("");
  const [animation, setAnimation] = useState("");
  const [movementStartTimes, setMovementStartTimes] = useState([]);

  useEffect(() => {
    setOpen(modalOpen);
    if (data) {
      setKey(data.key || "");
      setCharacterName(data.characterName || "");
      setProjectKey(data.projectKey || "");
      setLogo(data.logo || "");
      setText(data.text || "");
      setVideo(data.video || "");
      setAnimation(data.animation || "");
      setIdleStartTime(
        data.idleStartTime !== null ? data.idleStartTime.toString() : "0"
      );

      setIdleEndTime(
        data.idleEndTime !== null ? data.idleEndTime.toString() : "0"
      );
      if (
        typeof data.movementStartTime === "string" &&
        data.movementStartTime !== ""
      ) {
        const parsedArray = JSON.parse(data.movementStartTime);
        const movementStartTimesArray = parsedArray.map((value) =>
          parseFloat(value)
        );
        setMovementStartTimes(movementStartTimesArray);
      } else if (
        Array.isArray(data.movementStartTime) &&
        data.movementStartTime !== ""
      ) {
        const movementStartTimesArray = data.movementStartTime.map((value) =>
          parseFloat(value)
        );
        setMovementStartTimes(movementStartTimesArray);
      } else {
        setMovementStartTimes(null);
      }
    } else {
      setKey("");
      setCharacterName("");
      setProjectKey("");
      setLogo(null);
      setText("");
      setVideo(null);
      setAnimation("");
      setIdleStartTime("");
      setIdleEndTime("");
      setMovementStartTimes([]);
    }
  }, [modalOpen, data]);

  const addMovementStartTime = () => {
    setMovementStartTimes([...movementStartTimes, ""]);
  };
  const removeMovementStartTime = (index) => {
    const updatedTimes = [...movementStartTimes];
    updatedTimes.splice(index, 1);
    setMovementStartTimes(updatedTimes);
  };

  const handleVideoLoadedMetadata = (e) => {
    const video = e.target;
    setVideoDuration(video.duration);
  };
  const isInvalidStartTime = () => {
    if (videoDuration !== null) {
      return movementStartTimes.some(
        (time) => parseFloat(time) > videoDuration
      );
    }
    return false;
  };

  const handleVideoChange = (e) => {
    const file = e.target.files[0];
    setVideo(file);
    if (file) {
      const videoURL = URL.createObjectURL(file);
      setVideoPreviewURL(videoURL);
      setMovementStartTimes([]);
    }
  };

  const handleLogoChange = (e) => {
    const file = e.target.files[0];
    setLogo(file);
    if (file) {
      const logoURL = URL.createObjectURL(file);
      setLogoPreview(logoURL);
    }
  };

  let selectedRadio = animation === "Default" ? "Default" : "Custom";

  const savaHandler = (e) => {
    e.preventDefault();
    if (isInvalidStartTime()) {
      showErrorToast(
        "Some movement start times are greater than the video duration."
      );
      return;
    }
    const formData = new FormData();

    formData.append("key", key || "");
    formData.append("characterName", characterName || "");
    formData.append("projectKey", projectKey || "");
    formData.append("logo", logo || "");
    formData.append("text", text || "");

    if (animation === "Custom") {
      formData.append("video", video || "");
    } else {
      formData.append("video", "");
    }
    formData.append("animation", selectedRadio || "");
    if (animation === "Custom") {
      formData.append("idleStartTime", idleStartTime || "");
    } else {
      formData.append("idleStartTime", 0);
    }
    if (animation === "Custom") {
      formData.append("idleEndTime", idleEndTime || "");
    } else {
      formData.append("idleEndTime", 0);
    }

    if (animation === "Default") {
      movementStartTimes.forEach((time, index) => {
        formData.append(`movementStartTime[${index}]`, "0");
      });
    } else {
      movementStartTimes.forEach((time, index) => {
        formData.append(`movementStartTime[${index}]`, time);
      });
    }

    if (data) {
      try {
        http
          .put(`/soulMachines/${data.id}`, formData, {
            "Content-Type": "multipart/form-data",
          })
          .then((response) => {
            if (response.data.error === false) {
              showSuccessToast("Record updated.");
              console.log(response.data.message);
              setShowLoader(false);
              setRefresh(!refresh);
            } else {
              showErrorToast("Some error occured.");
              console.log(response.data.message);
              setShowLoader(false);
            }
          });
      } catch (error) {
        setShowLoader(false);
      }
    } else {
      try {
        http
          .post("/soulMachines/create", formData, {
            "Content-Type": "multipart/form-data",
          })
          .then((response) => {
            if (response.data.error === false) {
              showSuccessToast("Character added.");
              setShowLoader(false);
              setRefresh(!refresh);
            } else {
              showErrorToast("Some error occured!");
              console.log(response.message);
              setShowLoader(false);
            }
          });
      } catch (error) {
        setShowLoader(false);
      }
    }
    setShowLoader(true);
    closeModal();
  };

  const closeModal = () => {
    setKey("");
    setCharacterName("");
    setProjectKey("");
    setLogo(null);
    setText("");
    setVideo(null);
    setIdleStartTime("");
    setIdleEndTime("");
    setAnimation("");
    setMovementStartTimes([]);
    setModalOpen(false);
    setOpen(false);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all md:max-w-lg sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  <div className="mt-2 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      {modalTitle}
                    </Dialog.Title>

                    <form className="mt-3 text-left" onSubmit={savaHandler}>
                      <div className="mt-6 grid grid-cols-2 gap-y-6 gap-x-4 sm:grid-cols-6 ">
                        {/* Key Input */}
                        <div className="sm:col-span-6">
                          <label
                            htmlFor="char-key"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Key
                          </label>
                          <div className="mt-1">
                            <input
                              type="text"
                              name="char-key"
                              defaultValue={key}
                              onChange={(e) => {
                                setKey(e.target.value);
                              }}
                              id="char-key"
                              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            />
                          </div>
                        </div>
                        <div className="sm:col-span-6">
                          <label
                            htmlFor="char-key"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Character Name
                          </label>
                          <div className="mt-1">
                            <input
                              type="text"
                              name="char-key"
                              defaultValue={characterName}
                              onChange={(e) => {
                                setCharacterName(e.target.value);
                              }}
                              id="char-key"
                              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            />
                          </div>
                        </div>
                        {/* Character Name Input */}
                        <div className="sm:col-span-6">
                          <label
                            htmlFor="char-name"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Project Key
                          </label>
                          <div className="mt-1">
                            <input
                              defaultValue={projectKey}
                              onChange={(e) => {
                                setProjectKey(e.target.value);
                              }}
                              type="text"
                              name="char-name"
                              id="char-name"
                              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            />
                          </div>
                        </div>
                        <div className="sm:col-span-6 mt-2">
                          <div className="block text-sm font-medium text-gray-700">
                            Animation
                          </div>
                          <div className="flex items-center mt-2">
                            <label className="flex items-center mr-2">
                              Default
                              <input
                                className="ml-2"
                                type="radio"
                                name="chatViewType"
                                value="Default"
                                checked={animation === "Default"}
                                onChange={(e) => {
                                  setAnimation(
                                    e.target.checked ? "Default" : ""
                                  );
                                }}
                              />
                            </label>
                            <label className="flex items-center">
                              Custom
                              <input
                                className="ml-2"
                                type="radio"
                                name="chatViewType"
                                value="Custom"
                                checked={animation === "Custom"}
                                onChange={(e) => {
                                  setAnimation(
                                    e.target.checked ? "Custom" : ""
                                  );
                                }}
                              />
                            </label>
                          </div>
                        </div>
                        <div className="sm:col-span-6">
                          <label
                            htmlFor="logo"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Logo
                          </label>
                          <div className="mt-1 flex flex-row justify-between">
                            <div className="flex flex-row justify-between items-center">
                              <input
                                type="file"
                                name="logo"
                                onChange={handleLogoChange}
                              />
                            </div>
                            {!logoPreview && data && data.logo && (
                              <img
                                className="w-[3rem] h-[3rem]"
                                src={data.logo}
                                alt=""
                              />
                            )}
                            {logoPreview && typeof logoPreview === "string" ? (
                              <img
                                className="w-[3rem] h-[3rem]"
                                src={logoPreview}
                                alt="Logo Preview"
                              />
                            ) : null}
                          </div>
                        </div>
                        {animation === "Custom" && (
                          <div className="sm:col-span-6">
                            <label
                              htmlFor="video"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Video
                            </label>
                            <div className="mt-1">
                              <div className="flex justify-between items-center">
                                <input
                                  type="file"
                                  name="video"
                                  onChange={handleVideoChange}
                                />
                                {video && (
                                  <div>
                                    <video
                                      src={videoPreviewURL}
                                      className="w-[3rem] h-[3rem]"
                                      onLoadedMetadata={
                                        handleVideoLoadedMetadata
                                      }
                                    />
                                  </div>
                                )}
                                {!videoPreviewURL && data && data.video && (
                                  <video className="w-[3rem] h-[3rem]" controls>
                                    <source src={data.video} type="video/mp4" />
                                    Your browser does not support the video tag.
                                  </video>
                                )}
                              </div>
                              {videoDuration !== null && (
                                <p>Duration: {videoDuration.toFixed(0)} sec</p>
                              )}
                            </div>
                          </div>
                        )}

                        {/* {idletime} */}
                        {animation === "Custom" && (
                          <div className="sm:col-span-6">
                            <div class="flex gap-2 ">
                              <label
                                htmlFor="idleStartTime"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Idle Start Time
                              </label>
                              <Tooltip text="Start time when the avatar is not in motion or animating">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                  strokeWidth={2}
                                  className="h-5 w-5 cursor-pointer text-blue-gray-500"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                                  />
                                </svg>
                              </Tooltip>
                            </div>
                            <div className="mt-1">
                              <input
                                onChange={(e) => {
                                  setIdleStartTime(e.target.value);
                                }}
                                type="number"
                                name="idleStartTime"
                                id="idlestartTime"
                                defaultValue={idleStartTime}
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              />
                            </div>
                          </div>
                        )}
                        {animation === "Custom" && (
                          <div className="sm:col-span-6">
                            <div class="flex gap-2 ">
                              <label
                                htmlFor="idleEndTime"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Idle End Time
                              </label>
                              <Tooltip text="End time when the avatar is not in motion or animating">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                  strokeWidth={2}
                                  className="h-5 w-5 cursor-pointer text-blue-gray-500"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                                  />
                                </svg>
                              </Tooltip>
                            </div>
                            <div className="mt-1">
                              <input
                                onChange={(e) => {
                                  setIdleEndTime(e.target.value);
                                }}
                                type="number"
                                name="idleEndTime"
                                id="idleEndTime"
                                value={idleEndTime}
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              />
                            </div>
                          </div>
                        )}
                        {/* Movement Start Time input fields */}
                        {animation === "Custom" && (
                          <div className="sm:col-span-6 mb-4 mt-4">
                            {movementStartTimes !== null &&
                              movementStartTimes.map((value, index) => (
                                <div key={index} className="sm:col-span-6 mt-2">
                                  <div className="flex gap-2 items-center">
                                    <label
                                      htmlFor={`movementStartTime${index}`}
                                      className="block text-sm font-medium text-gray-700"
                                    >
                                      Movement Start Time {index + 1}
                                    </label>
                                    <Tooltip text="Time when the avatar is in motion or animating">
                                      {/* Add a "Remove" button */}
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                        className="h-5 w-5 cursor-pointer text-blue-gray-500"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                                        />
                                      </svg>
                                    </Tooltip>
                                    <button
                                      type="button"
                                      onClick={() =>
                                        removeMovementStartTime(index)
                                      }
                                      className="text-red-600 hover:text-red-800"
                                    >
                                      <TrashIcon className="h-6 w-6 text-red-500" />
                                    </button>
                                  </div>
                                  <div className="mt-1">
                                    <input
                                      type="text"
                                      value={value}
                                      onChange={(e) => {
                                        const updatedTimes = [
                                          ...movementStartTimes,
                                        ];
                                        updatedTimes[index] = e.target.value;
                                        setMovementStartTimes(updatedTimes);
                                      }}
                                      id={`movementStartTime${index}`}
                                      name={`movementStartTime${index}`}
                                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    />
                                  </div>
                                </div>
                              ))}
                            {/* Button to add a new Movement Start Time field */}
                            {animation === "Custom" && (
                              <div className="sm:col-span-6 mt-4">
                                <button
                                  type="button"
                                  onClick={addMovementStartTime}
                                  className="!bg-blue-500 text-white font-bold py-2 px-4 rounded"
                                >
                                  Add Movement Start Time
                                </button>
                              </div>
                            )}
                          </div>
                        )}
                        {/* Text input */}
                        <div className="sm:col-span-6">
                          <label
                            htmlFor="char-text"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Text
                          </label>
                          <div className="mt-1">
                            <textarea
                              defaultValue={text}
                              id="char-text"
                              name="char-text"
                              onChange={(e) => {
                                setText(e.target.value);
                              }}
                              rows={3}
                              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            />
                          </div>
                        </div>
                        <div className="mt-3">
                          <div className="flex flex-row justify-end">
                            <button
                              onClick={(e) => savaHandler(e)}
                              type="submit"
                              className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
