import React, { Fragment, useRef, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import http from "../services/http";
import { showSuccessToast, showErrorToast } from "../utils/toast";
import { TrashIcon } from "@heroicons/react/outline";
import Tooltip from "./ToolTip";
import { toast } from "react-toastify";

export default function ModalCreateAI({
  data,
  modalOpen,
  setModalOpen,
  modalTitle,
  refresh,
  setRefresh,
  // setShowLoader,
}) {
  let isUpdating = false;
  // forms refs
  const [open, setOpen] = useState(modalOpen);
  const [languageState, setLanguageState] = useState();
  const [key, setKey] = useState();
  const [characterName, setCharacterName] = useState();
  const [sceneName, setSceneName] = useState();
  const [userName, setUserName] = useState();
  const [text, setText] = useState();
  const [charColor, setCharColor] = useState("#000000");
  const [textColor, setTextColor] = useState("#000000");
  const [gradientColor, setGradientColor] = useState("#000000");
  const [backgroundColor, setBackgroundColor] = useState("#000000");
  const [selectedValue, setSelectedValue] = useState();
  const [history, setHistory] = useState();
  const [chatBar, setChatBar] = useState();
  const [gender, setGender] = useState();
  const [voice, setVoice] = useState();
  const [voiceName, setVoiceName] = useState();
  const [logo, setLogo] = useState("");
  const [video, setVideo] = useState("");
  const [videoDuration, setVideoDuration] = useState("");
  const [idleStartTime, setIdleStartTime] = useState("");
  const [idleEndTime, setIdleEndTime] = useState("");
  const [movementStartTimes, setMovementStartTimes] = useState([]);
  const [videoPreviewURL, setVideoPreviewURL] = useState(null);
  const [logoPreview, setLogoPreview] = useState(null);
  const [isMuted, setIsMuted] = useState();

  // if data then set the forms with data values  --case update
  useEffect(() => {
    console.log(data);
    if (data) {
      isUpdating = true;
      setKey(data.key);
      setCharacterName(data.characterName);
      setSceneName(data.sceneName);
      setUserName(data.userName);
      setText(data.text);
      setTextColor(data.textColor);
      setCharColor(data.characterColor);
      setGradientColor(data.gradientColor);
      setBackgroundColor(data.backgroundColor);
      setLanguageState(data.language);
      setGender(data.gender);
      setVoice(data.voice);
      setVoiceName(data.voiceName);
      setLogo(data.logo);
      setHistory(data.chat_history);
      setSelectedValue(data.chat_view);
      setChatBar(data.chat_bar);
      setVideo(data.video);
      setIsMuted(data.isMuted);
      setIdleStartTime(
        data.idleStartTime !== null ? data.idleStartTime.toString() : "0"
      );

      setIdleEndTime(
        data.idleEndTime !== null ? data.idleEndTime.toString() : "0"
      );
      if (
        typeof data.movementStartTime === "string" &&
        data.movementStartTime !== ""
      ) {
        const parsedArray = JSON.parse(data.movementStartTime);
        const movementStartTimesArray = parsedArray.map((value) =>
          parseFloat(value)
        );
        setMovementStartTimes(movementStartTimesArray);
      } else if (
        Array.isArray(data.movementStartTime) &&
        data.movementStartTime !== ""
      ) {
        const movementStartTimesArray = data.movementStartTime.map((value) =>
          parseFloat(value)
        );
        setMovementStartTimes(movementStartTimesArray);
      } else {
        setMovementStartTimes(null);
      }
    }
  }, [data]);
  // Reset Refs

  const resetRefs = () => {
    console.log("reseting Refs");
    setKey("");
    setCharacterName("");
    setSceneName("");
    setUserName("");
    setTextColor("");
    setGradientColor("");
    setBackgroundColor("");
    setCharColor("");
    setText("");
    setLanguageState("");
    setChatBar("");
    setGender("");
    setVoice("");
    setVoiceName("");
    setHistory("");
    setSelectedValue("");
    setVideo("");
    setIdleStartTime("");
    setIdleEndTime("");
    setMovementStartTimes("");
    setIsMuted("");
  };
  const addMovementStartTime = () => {
    if (video) {
      setMovementStartTimes([...movementStartTimes, ""]);
    } else {
      toast.error("Please upload your video.");
    }
  };
  const removeMovementStartTime = (index) => {
    const updatedTimes = [...movementStartTimes];
    updatedTimes.splice(index, 1);
    setMovementStartTimes(updatedTimes);
  };

  const handleVideoLoadedMetadata = (e) => {
    const video = e.target;
    setVideoDuration(video.duration);
  };
  const isInvalidStartTime = () => {
    if (videoDuration) {
      return movementStartTimes.some(
        (time) => parseFloat(time) >= videoDuration
      );
    }
    return false;
  };
  const handleLogoChange = (e) => {
    const file = e.target.files[0];
    setLogo(file);
    if (file) {
      const logoURL = URL.createObjectURL(file);
      setLogoPreview(logoURL);
    }
  };
  const handleVideoChange = (e) => {
    const file = e.target.files[0];
    setVideo(file);
    if (file) {
      const videoURL = URL.createObjectURL(file);
      setVideoPreviewURL(videoURL);
      setMovementStartTimes([]);
    }
  };
  const savaHandler = (e) => {
    e.preventDefault();

    if (video || videoDuration === "") {
      if (isInvalidStartTime()) {
        showErrorToast(
          "Some movement start times are greater than the video duration."
        );
        return;
      }
    }
    const selectedRadio =
      selectedValue === "Text"
        ? "Text"
        : selectedValue === "Avatar"
        ? "Avatar"
        : selectedValue === "Video"
        ? "Video"
        : "";
    const chatHistory = history ? 1 : 0;
    const chat_Bar = chatBar ? 1 : 0;
    const muted = isMuted ? 1 : 0;

    const formData = new FormData();

    formData.append("key", key || "");
    formData.append("characterName", characterName || "");
    formData.append("sceneName", sceneName || "");
    formData.append("userName", userName || "");
    formData.append("textColor", textColor || "");
    formData.append("gradientColor", gradientColor || "");
    formData.append("backgroundColor", backgroundColor || "");
    formData.append("characterColor", charColor || "");
    formData.append("text", text || "");
    formData.append("chat_view", selectedRadio || "");
    formData.append("chat_history", chatHistory || "");
    formData.append("chat_bar", chat_Bar || "");
    formData.append("language", languageState || "");
    formData.append("gender", gender || "");
    formData.append("isMuted", muted || "");
    formData.append("voice", voice);
    formData.append("voiceName", voiceName || "");
    if (selectedValue !== "Avatar" || selectedValue !== "Text") {
      formData.append("video", video || "");
    } else {
      formData.append("video", "");
    }
    formData.append("animation", selectedRadio || "");
    if (selectedValue !== "Avatar" || selectedValue !== "Text") {
      formData.append("idleStartTime", idleStartTime || "");
    } else {
      formData.append("idleStartTime", 0);
    }
    if (selectedValue !== "Avatar" || selectedValue !== "Text") {
      formData.append("idleEndTime", idleEndTime || "");
    } else {
      formData.append("idleEndTime", 0);
    }

    if (selectedValue === "Avatar" || selectedValue === "Text") {
      if (movementStartTimes) {
        movementStartTimes.forEach((time, index) => {
          formData.append(`movementStartTime[${index}]`, "0");
        });
      }
    } else {
      movementStartTimes.forEach((time, index) => {
        formData.append(`movementStartTime[${index}]`, time || "");
      });
    }

    if (logo) {
      formData.append("logo", logo);
    }

    if (data) {
      try {
        http
          .put(`/character/${data.id}`, formData, {
            "Content-Type": "multipart/form-data",
          })
          .then((response) => {
            if (response.data.error === false) {
              showSuccessToast("Record updated.");
              console.log(response.data.message);
              setRefresh(!refresh);
            } else {
              showErrorToast("Some error occurred.");
              console.log(response.data.message);
            }
          });
      } catch (error) {}
    } else {
      try {
        http
          .post("/character", formData, {
            "Content-Type": "multipart/form-data",
          })
          .then((response) => {
            if (response.data.error === false) {
              showSuccessToast("Character added.");
              setRefresh(!refresh);
            } else {
              showErrorToast("Some error occurred!");
              console.log(response.message);
            }
          });
      } catch (error) {}
    }

    closeModal();
  };

  const closeModal = () => {
    resetRefs();
    setModalOpen(false);
    setOpen(false);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all md:max-w-lg sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  <div className="mt-2 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      {modalTitle}
                    </Dialog.Title>

                    <form className="mt-3 text-left" onSubmit={savaHandler}>
                      <div className="mt-6 grid grid-cols-2 gap-y-6 gap-x-4 sm:grid-cols-6 ">
                        {/* Key Input */}
                        <div className="sm:col-span-6">
                          <label
                            htmlFor="char-key"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Key
                          </label>
                          <div className="mt-1">
                            <input
                              type="text"
                              name="char-key"
                              defaultValue={key}
                              onChange={(e) => {
                                setKey(e.target.value);
                              }}
                              id="char-key"
                              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            />
                          </div>
                        </div>
                        {/* Character Name Input */}
                        <div className="sm:col-span-6">
                          <label
                            htmlFor="char-name"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Character Name
                          </label>
                          <div className="mt-1">
                            <input
                              defaultValue={characterName}
                              onChange={(e) => {
                                setCharacterName(e.target.value);
                              }}
                              type="text"
                              name="char-name"
                              id="char-name"
                              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            />
                          </div>
                        </div>
                        {/* Scene Name Input */}
                        <div className="sm:col-span-6">
                          <label
                            htmlFor="scene-name"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Scene Name
                          </label>
                          <div className="mt-1">
                            <input
                              defaultValue={sceneName}
                              onChange={(e) => {
                                setSceneName(e.target.value);
                              }}
                              id="scene-name"
                              name="scene-name"
                              type="text"
                              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            />
                          </div>
                        </div>

                        {/* User Name Input */}
                        <div className="sm:col-span-6">
                          <label
                            htmlFor="user-name"
                            className="block text-sm font-medium text-gray-700"
                          >
                            User Name
                          </label>
                          <div className="mt-1">
                            <input
                              defaultValue={userName}
                              onChange={(e) => {
                                setUserName(e.target.value);
                              }}
                              type="text"
                              name="user-name"
                              id="user-name"
                              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            />
                          </div>
                        </div>
                        {/* Text input */}
                        <div className="sm:col-span-6">
                          <label
                            htmlFor="char-text"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Text
                          </label>
                          <div className="mt-1">
                            <textarea
                              defaultValue={text}
                              id="char-text"
                              name="char-text"
                              onChange={(e) => {
                                setText(e.target.value);
                              }}
                              rows={3}
                              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            />
                          </div>
                        </div>
                        {/* Logo Input */}
                        <div className="sm:col-span-6">
                          <label
                            htmlFor="logo"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Logo
                          </label>
                          <div className="mt-1 flex flex-row justify-between">
                            <div className="flex flex-row justify-between items-center">
                              <input
                                type="file"
                                name="logo"
                                onChange={handleLogoChange}
                              />
                            </div>
                            {!logoPreview && data && data.logo && (
                              <img
                                className="w-[3rem] h-[3rem]"
                                src={data.logo}
                                alt=""
                              />
                            )}
                            {logoPreview && typeof logoPreview === "string" ? (
                              <img
                                className="w-[3rem] h-[3rem]"
                                src={logoPreview}
                                alt="Logo Preview"
                              />
                            ) : null}
                          </div>
                        </div>
                        {/* Color Input */}
                        <div className="sm:col-span-3">
                          <label
                            htmlFor="color"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Text Color
                          </label>
                          <Tooltip text="This color set the overall text colors of the modal">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              strokeWidth={2}
                              className="h-5 w-4 cursor-pointer text-blue-gray-500"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                              />
                            </svg>
                          </Tooltip>
                          <div className="mt-1">
                            <input
                              value={textColor}
                              onChange={(e) => {
                                setTextColor(e.target.value);
                              }}
                              type="color"
                              name="color"
                              id="color"
                              className="p-2 h-9 w-9 block  rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            />
                          </div>
                        </div>
                        <div className="sm:col-span-3">
                          <label
                            htmlFor="char-color"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Character Color
                          </label>
                          <Tooltip text="This color set the overall theme of the modal">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              strokeWidth={2}
                              className="h-5 w-4 cursor-pointer text-blue-gray-500"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                              />
                            </svg>
                          </Tooltip>
                          <div className="mt-1">
                            <input
                              value={charColor}
                              onChange={(e) => {
                                setCharColor(e.target.value);
                              }}
                              type="color"
                              name="char-color"
                              id="char-color"
                              className="p-2 h-9 w-9 block  rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            />
                          </div>
                        </div>
                        <div className="sm:col-span-3">
                          <label
                            htmlFor="gradient-color"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Gradient Color/ AI Response
                          </label>
                          <Tooltip text="This color set the color background of AI Response">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              strokeWidth={2}
                              className="h-5 w-4 cursor-pointer text-blue-gray-500"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                              />
                            </svg>
                          </Tooltip>
                          <div className="mt-1">
                            <input
                              value={gradientColor}
                              onChange={(e) => {
                                setGradientColor(e.target.value);
                              }}
                              type="color"
                              name="gradient-color"
                              id="gradient-color"
                              className="p-2 h-9 w-9 block  rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            />
                          </div>
                        </div>
                        <div className="sm:col-span-3">
                          <label
                            htmlFor="gradient-color"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Avatar Background
                          </label>
                          <Tooltip text="This color set the background color of text modal">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              strokeWidth={2}
                              className="h-5 w-4 cursor-pointer text-blue-gray-500"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                              />
                            </svg>
                          </Tooltip>
                          <div className="mt-1">
                            <input
                              value={backgroundColor}
                              onChange={(e) => {
                                setBackgroundColor(e.target.value);
                              }}
                              type="color"
                              name="gradient-color"
                              id="gradient-color"
                              className="p-2 h-9 w-9 block  rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            />
                          </div>
                        </div>
                        <div className="sm:col-span-6">
                          <div className="block text-sm font-medium text-gray-700">
                            Chat View
                          </div>
                          <div className="flex items-center">
                            <label className="flex items-center mr-2">
                              Text
                              <input
                                className="ml-2"
                                type="radio"
                                name="chatViewType"
                                value="Text"
                                checked={selectedValue === "Text"}
                                onChange={(e) => {
                                  setSelectedValue(
                                    e.target.checked ? "Text" : ""
                                  );
                                }}
                              />
                            </label>
                            <label className="flex items-center">
                              Avatar
                              <input
                                className="ml-2"
                                type="radio"
                                name="chatViewType"
                                value="Avatar"
                                checked={selectedValue === "Avatar"}
                                onChange={(e) => {
                                  setSelectedValue(
                                    e.target.checked ? "Avatar" : ""
                                  );
                                }}
                              />
                            </label>
                            <label className="flex items-center ml-2">
                              Video
                              <input
                                className="ml-2"
                                type="radio"
                                name="chatViewType"
                                value="Video"
                                checked={selectedValue === "Video"}
                                onChange={(e) => {
                                  setSelectedValue(
                                    e.target.checked ? "Video" : ""
                                  );
                                }}
                              />
                            </label>
                          </div>
                        </div>

                        {selectedValue === "Video" && (
                          <div className="sm:col-span-6">
                            <label
                              htmlFor="video"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Video
                            </label>
                            <div className="mt-1">
                              <div className="flex justify-between items-center">
                                <input
                                  type="file"
                                  name="video"
                                  onChange={handleVideoChange}
                                />
                                {video && (
                                  <div>
                                    <video
                                      src={videoPreviewURL}
                                      className="w-[3rem] h-[3rem]"
                                      onLoadedMetadata={
                                        handleVideoLoadedMetadata
                                      }
                                    />
                                  </div>
                                )}
                                {!videoPreviewURL && data && data.video && (
                                  <video className="w-[3rem] h-[3rem]" controls>
                                    <source src={data.video} type="video/mp4" />
                                    Your browser does not support the video tag.
                                  </video>
                                )}
                              </div>
                              {videoDuration !== null && videoDuration && (
                                <p>Duration: {videoDuration.toFixed(0)} sec</p>
                              )}
                            </div>
                          </div>
                        )}
                        {/* {idletime} */}
                        {selectedValue === "Video" && (
                          <div className="sm:col-span-6">
                            <div class="flex gap-2 ">
                              <label
                                htmlFor="idleStartTime"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Idle Start Time
                              </label>
                              <Tooltip text="Start time when the avatar is not in motion or animating">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                  strokeWidth={2}
                                  className="h-5 w-5 cursor-pointer text-blue-gray-500"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                                  />
                                </svg>
                              </Tooltip>
                            </div>
                            <div className="mt-1">
                              <input
                                onChange={(e) => {
                                  setIdleStartTime(e.target.value);
                                }}
                                type="number"
                                name="idleStartTime"
                                id="idlestartTime"
                                defaultValue={idleStartTime}
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              />
                            </div>
                          </div>
                        )}
                        {selectedValue === "Video" && (
                          <div className="sm:col-span-6">
                            <div class="flex gap-2 ">
                              <label
                                htmlFor="idleEndTime"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Idle End Time
                              </label>
                              <Tooltip text="End time when the avatar is not in motion or animating">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                  strokeWidth={2}
                                  className="h-5 w-5 cursor-pointer text-blue-gray-500"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                                  />
                                </svg>
                              </Tooltip>
                            </div>
                            <div className="mt-1">
                              <input
                                onChange={(e) => {
                                  setIdleEndTime(e.target.value);
                                }}
                                type="number"
                                name="idleEndTime"
                                id="idleEndTime"
                                value={idleEndTime}
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              />
                            </div>
                          </div>
                        )}
                        {/* Movement Start Time input fields */}
                        {selectedValue === "Video" && (
                          <div className="sm:col-span-6">
                            {movementStartTimes !== null &&
                              movementStartTimes &&
                              movementStartTimes.map((value, index) => (
                                <div key={index}>
                                  <div className="flex gap-2 items-center">
                                    <label
                                      htmlFor={`movementStartTime${index}`}
                                      className="block text-sm font-medium text-gray-700"
                                    >
                                      Movement Start Time {index + 1}
                                    </label>
                                    <Tooltip text="Time when the avatar is in motion or animating">
                                      {/* Add a "Remove" button */}

                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                        className="h-5 w-5 cursor-pointer text-blue-gray-500"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                                        />
                                      </svg>
                                    </Tooltip>
                                    <button
                                      type="button"
                                      onClick={() =>
                                        removeMovementStartTime(index)
                                      }
                                      className="text-red-600 hover:text-red-800"
                                    >
                                      <TrashIcon className="h-6 w-6 text-red-500" />
                                    </button>
                                  </div>
                                  <div className="mt-1">
                                    <input
                                      type="text"
                                      value={value}
                                      onChange={(e) => {
                                        const updatedTimes = [
                                          ...movementStartTimes,
                                        ];
                                        updatedTimes[index] = e.target.value;
                                        setMovementStartTimes(updatedTimes);
                                      }}
                                      id={`movementStartTime${index}`}
                                      name={`movementStartTime${index}`}
                                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    />
                                  </div>
                                </div>
                              ))}
                          </div>
                        )}
                        {/* Button to add a new Movement Start Time field */}
                        {selectedValue === "Video" && (
                          <div className="sm:col-span-6">
                            <button
                              type="button"
                              onClick={addMovementStartTime}
                              className="!bg-blue-500 text-white font-bold py-2 px-4 rounded"
                            >
                              Add Movement Start Time
                            </button>
                          </div>
                        )}
                        <div className="sm:col-span-6">
                          <div className="block text-sm font-medium text-gray-700">
                            Chat History
                          </div>
                          <label>
                            <input
                              className="mr-2"
                              type="checkbox"
                              checked={history}
                              onChange={(e) => {
                                setHistory(e.target.checked);
                              }}
                            />
                            Show all history
                          </label>
                        </div>
                        <div className="sm:col-span-6">
                          <div className="block text-sm font-medium text-gray-700">
                            Is Muted
                          </div>
                          <label>
                            <input
                              className="mr-2"
                              type="checkbox"
                              checked={isMuted}
                              onChange={(e) => {
                                setIsMuted(e.target.checked);
                              }}
                            />
                            Is Muted
                          </label>
                        </div>
                        <div className="sm:col-span-6">
                          <div className="block text-sm font-medium text-gray-700">
                            Chat Bar
                          </div>
                          <label>
                            <input
                              className="mr-2"
                              type="checkbox"
                              defaultChecked={chatBar}
                              checked={chatBar}
                              onChange={(e) => {
                                setChatBar(e.target.checked);
                              }}
                            />
                            Show Chat Bar
                          </label>
                        </div>
                        <div className="sm:col-span-6 mt-4">
                          <label
                            htmlFor="language"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Select Language
                          </label>
                          {/* https://cloud.google.com/speech-to-text/docs/speech-to-text-supported-languages */}
                          {/* visit this link to add more languages and their respective codes */}

                          <select
                            id="language"
                            value={languageState}
                            onChange={(e) => setLanguageState(e.target.value)}
                            name="language"
                            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          >
                            <option value="">
                              Select your prefered language
                            </option>
                            <option value="en-uk">English (UK)</option>
                            <option value="en">English</option>
                            <option value="zh">Mandarin</option>
                            <option value="ar">Arabic</option>
                          </select>
                        </div>
                        <div className="sm:col-span-6 mt-4">
                          <label
                            htmlFor="language"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Select Gender
                          </label>
                          {/* https://cloud.google.com/speech-to-text/docs/speech-to-text-supported-languages */}
                          {/* visit this link to add more languages and their respective codes */}

                          <select
                            id="language"
                            value={gender}
                            onChange={(e) => {
                              setGender(e.target.value);
                            }}
                            name="language"
                            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          >
                            <option value="">
                              Select your prefered Gender
                            </option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                          </select>
                        </div>
                        <div className="sm:col-span-6 mt-4">
                          <label
                            htmlFor="language"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Select Voice Type
                          </label>
                          <select
                            id="language"
                            value={voice}
                            onChange={(e) => setVoice(e.target.value)}
                            name="language"
                            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          >
                            <option value="">Select your prefered Voice</option>
                            <option value="Wavenet">Wavenet</option>
                            <option value="Basic">Basic</option>
                          </select>
                        </div>
                        <div className="sm:col-span-6 mt-4">
                          <label
                            htmlFor="language"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Select Voice Name
                          </label>
                          {/* https://cloud.google.com/speech-to-text/docs/speech-to-text-supported-languages */}
                          {/* visit this link to add more languages and their respective codes */}

                          <select
                            id="language"
                            value={voiceName}
                            onChange={(e) => {
                              setVoiceName(e.target.value);
                            }}
                            name="language"
                            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          >
                            <option value="">
                              Select your prefered Voice name
                            </option>
                            {languageState === "en-uk" && voice === "Basic" && (
                              <>
                                <option value="en-GB-Neural2-B">
                                  en-GB-Neural2-B
                                </option>{" "}
                                <option value="en-GB-Neural2-C">
                                  en-GB-Neural2-C
                                </option>{" "}
                                <option value="en-GB-Neural2-D">
                                  en-GB-Neural2-D
                                </option>{" "}
                                <option value="en-GB-Neural2-F">
                                  en-GB-Neural2-F
                                </option>
                              </>
                            )}
                            {languageState === "en" && voice === "Wavenet" && (
                              <>
                                <option value="en-US-Wavenet-A">en-US</option>
                                <option value="en-US-Wavenet-C">
                                  en-US-Wavenet-C
                                </option>
                                <option value="en-US-Wavenet-D">
                                  en-US-Wavenet-D
                                </option>
                                <option value="en-US-Wavenet-E">
                                  en-US-Wavenet-E
                                </option>
                                <option value="en-US-Wavenet-G">
                                  en-US-Wavenet-G
                                </option>
                                <option value="en-US-Neural2-D">
                                  en-US-Neural2-D
                                </option>
                              </>
                            )}
                            {languageState === "en" && voice === "Basic" && (
                              <>
                                <option value="en-US-Standard-A">en-US</option>
                              </>
                            )}
                            {languageState === "zh" && voice === "Basic" && (
                              <>
                                <option value="cmn-CN-Standard-C">
                                  cmn-CN
                                </option>
                              </>
                            )}
                            {languageState === "zh" && voice === "Wavenet" && (
                              <>
                                <option value="cmn-CN-Wavenet-C">cmn-CN</option>
                              </>
                            )}
                            {languageState === "ar" && voice === "Wavenet" && (
                              <>
                                <option value="ar-XA-Wavenet-A">ar-XA</option>
                              </>
                            )}
                            {languageState === "ar" && voice === "Basic" && (
                              <>
                                <option value="ar-XA-Standard-A">ar-XA</option>
                              </>
                            )}
                          </select>
                        </div>
                      </div>
                      <div className="mt-3">
                        <div className="flex flex-row justify-end">
                          <button
                            onClick={(e) => savaHandler(e)}
                            type="submit"
                            className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
