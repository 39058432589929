import React, { Fragment, useRef, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import http from "../services/http";
import { showSuccessToast, showErrorToast } from "../utils/toast";
export default function ModalCreateEmotion({
  data,
  modalOpen,
  setModalOpen,
  modalTitle,
  refresh,
  setRefresh,
  emotionOptions,
}) {
  const [open, setOpen] = useState(modalOpen);
  let isUpdating = false;

  const emotionNameRef = useRef(null);
  const emotionIdRef = useRef(null);
  const emotionAttrRef = useRef(null);
  const attrIdRef = useRef(null);
  const attrValueRef = useRef(null);
  const selectedEmotionIdRef = useRef();
  const listOfEmotionsRef = useRef([]);

  if (data) {
    isUpdating = true;
    emotionIdRef.current = data.emotionId;
    attrIdRef.current = data.attributeId;
    emotionNameRef.current = data.emotionName;
    emotionAttrRef.current = data.attribute;
    attrValueRef.current = data.value;
  }

  const resetRefs = () => {
    emotionNameRef.current = "";
    emotionAttrRef.current = "";
    attrValueRef.current = "";
  };
  const saveHandler = (e) => {
    e.preventDefault();
    let body = new Object({
      attributeName: emotionAttrRef.current.value,
      attributeValue: attrValueRef.current.value,
      emotionId: emotionIdRef.current,
      attributeId: attrIdRef.current,
      selectedEmotionIdRef: selectedEmotionIdRef.current,
    });
    console.log(body);
    if (isUpdating) {
      console.log("updating..");
      try {
        http
          .put(`/emotion/updateAttr`, body, {
            "Content-Type": "application/json",
          })
          .then((response) => {
            if (response.status === 200) {
              showSuccessToast("Record updated.");
              setRefresh(!refresh);
            } else {
              showErrorToast("Some error occured.");
              console.log(response.data.message);
            }
          });
      } catch (error) {}
    } else {
      console.log("Creating Attribute");
      try {
        http
          .post(`/emotion/${selectedEmotionIdRef.current}`, body, {
            "Content-Type": "application/json",
          })
          .then((response) => {
            console.log(response);
            if (response.status === 201) {
              showSuccessToast("Attribute added.");
              setRefresh(!refresh);
            } else {
              showErrorToast("Some error occured!");
              console.log(response.message);
            }
          });
      } catch (error) {}
    }
    closeModal();
  };

  const closeModal = () => {
    resetRefs();
    setModalOpen(false);
    setOpen(false);
  };
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all md:max-w-lg sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  <div className="mt-2 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      {modalTitle}
                    </Dialog.Title>
                    <form className="mt-3 text-left" onSubmit={saveHandler}>
                      <div className="mt-6 grid grid-cols-2 gap-y-6 gap-x-4 sm:grid-cols-6">
                        {/* Emotion Input (Dynamic Dropdown) */}
                        <div className="sm:col-span-6">
                          <label
                            htmlFor="char-key"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Emotion
                          </label>
                          <div className="mt-1">
                            {
                              <select
                                onChange={(e) => {
                                  const selectedEmotion = emotionOptions.find(
                                    (emotion) =>
                                      emotion.emotion === e.target.value
                                  );
                                  console.log(emotionOptions);
                                  if (selectedEmotion) {
                                    selectedEmotionIdRef.current =
                                      selectedEmotion.id;
                                    console.log(selectedEmotionIdRef.current);
                                  } else {
                                    selectedEmotionIdRef.current = null;
                                  }
                                }}
                                defaultValue={emotionNameRef.current}
                                id="char-key"
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              >
                                <option value="">Select an Emotion</option>
                                {emotionOptions.map((emotion) => (
                                  <option
                                    key={emotion.id}
                                    value={emotion.emotion}
                                  >
                                    {emotion.emotion}
                                  </option>
                                ))}
                              </select>
                            }
                          </div>
                        </div>

                        {/* Character Name Input */}
                        <div className="sm:col-span-6">
                          <label
                            htmlFor="char-name"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Emotion Attribute
                          </label>
                          <div className="mt-1">
                            <select
                              ref={emotionAttrRef}
                              defaultValue={emotionAttrRef.current}
                              onChange={() => {}}
                              name="char-name"
                              id="char-name"
                              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            >
                              <option value="">
                                Select your Emotion Attribute
                              </option>
                              <option value="eyeSquintLeft">
                                eyeSquintLeft
                              </option>
                              <option value="mouthUpperUpLeft">
                                mouthUpperUpLeft
                              </option>
                              <option value="browInnerUp">browInnerUp</option>
                              <option value="noseSneerRight">
                                noseSneerRight
                              </option>
                              <option value="noseSneerLeft">
                                noseSneerLeft
                              </option>
                              <option value="eyeSquintRight">
                                eyeSquintRight
                              </option>
                              <option value="eyeClosed">eyeClosed</option>
                              <option value="mouthShrugUpper">
                                mouthShrugUpper
                              </option>
                              <option value="browDownLeft">browDownLeft</option>
                              <option value="browDownRight">
                                browDownRight
                              </option>
                              <option value="eyeWideRight">eyeWideRight</option>
                              <option value="browOuterUpLeft">
                                browOuterUpLeft
                              </option>
                              <option value="jawOpen">jawOpen</option>
                              <option value="browOuterUpRight">
                                browOuterUpRight
                              </option>
                              <option value="cheekSquintLeft">
                                cheekSquintLeft
                              </option>
                              <option value="browOuterUpLeft">
                                browOuterUpLeft
                              </option>
                              <option value="mouthSmile">mouthSmile</option>
                              <option value="eyeWideLeft">eyeWideLeft</option>
                              <option value="cheekSquintRight">
                                cheekSquintRight
                              </option>
                              <option value="eyeSquintLeft">
                                eyeSquintLeft
                              </option>
                              <option value="mouthRollLower">
                                mouthRollLower
                              </option>
                              <option value="browOuterUpRight">
                                browOuterUpRight
                              </option>
                              <option value="browInnerUp">browInnerUp</option>
                              <option value="mouthRollUpper">
                                mouthRollUpper
                              </option>
                              <option value="mouthShrugLower">
                                mouthShrugLower
                              </option>
                              <option value="mouthFrownLeft">
                                mouthFrownLeft
                              </option>
                              <option value="mouthFrownRight">
                                mouthFrownRight
                              </option>
                              <option value="eyesClosed">eyesClosed</option>
                              <option value="jawForward">jawForward</option>
                              <option value="mouthOpen">mouthOpen</option>
                            </select>
                          </div>
                        </div>
                        {/* Scene Name Input */}
                        <div className="sm:col-span-6">
                          <label
                            htmlFor="scene-name"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Value
                          </label>
                          <div className="mt-1">
                            <input
                              ref={attrValueRef}
                              defaultValue={attrValueRef.current}
                              onChange={(e) => {}}
                              id="scene-name"
                              name="scene-name"
                              type="text"
                              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            />
                          </div>
                        </div>

                        <div className="mt-3">
                          <div className="flex flex-row justify-end">
                            <button
                              onClick={(e) => saveHandler(e)}
                              type="submit"
                              className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
