import React, { useEffect, useState } from "react";
import ModalCreateAI from "../components/soulModal";
import { TrashIcon, PencilIcon } from "@heroicons/react/outline";
import http from "../services/http";
import { showErrorToast, showSuccessToast } from "../utils/toast";
import ReactModal from "react-modal";
import { Space, Spin } from "antd";
const SoulMachinesTable = () => {
  const [showLoader, setShowLoader] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [videoModalOpen, setVideoModalOpen] = useState(false);
  const [characters, setChracters] = useState([]);
  const [modalTitle, setModalTitle] = useState("");
  const [data, setData] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState("");
  const getChracters = () => {
    http
      .get("soulMachines", {
        "Content-Type": "application/json",
      })
      .then((response) => {
        const data = response.data;
        setChracters(data.data);
      })
      .catch((error) => {
        console.log(error);
        setShowLoader(false);
      })
      .finally(() => {
        setShowLoader(false);
      });
  };

  useEffect(() => {
    getChracters();
  }, [refresh]);

  //  Delete Character
  const handleDeleteCharacter = (id) => {
    http
      .delete(`/soulMachines/${id}`, {
        "Content-Type": "application/json",
      })
      .then((response) => {
        if (response.data.error === false) {
          showSuccessToast("Character deleted.");
          setRefresh(!refresh);
          // setTempVar(!tempVar);
        } else {
          showErrorToast("Error while deleting!");
          console.log(response.message);
        }
      });
  };
  const openVideoModal = (videoUrl) => {
    setSelectedVideo(videoUrl);
    setVideoModalOpen(true);
  };

  return (
    <>
      {showLoader ? (
        <div className="loader h-screen  grid place-content-center bg-white">
          <Space size="middle">
            <Spin size="large" />
          </Space>
        </div>
      ) : (
        <div>
          {modalOpen && (
            <ModalCreateAI
              modalOpen={modalOpen}
              setModalOpen={setModalOpen}
              modalTitle={modalTitle}
              data={data}
              refresh={refresh}
              setRefresh={setRefresh}
              setShowLoader={setShowLoader}
            />
          )}

          <div className="px-6 lg:px-8">
            <div className="flex flex-row justify-between items-center">
              <div className="sm:flex-auto">
                <h1 className="text-xl font-semibold text-gray-900">
                  Soul Machines List
                </h1>
                <p className="mt-2 text-sm text-gray-700">
                  Create Soul Machines Character Panel
                </p>
              </div>
              <div className="sm:mt-0 sm:ml-16 sm:flex-none">
                <button
                  type="button"
                  className="block rounded-md bg-indigo-600 py-1.5 px-3 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  onClick={() => {
                    setModalTitle("Create New AI Character");
                    setData(null);
                    setModalOpen(!modalOpen);
                  }}
                >
                  Create AI
                </button>
              </div>
            </div>
            <div className="mt-8 flow-root">
              <div className="-my-2 -mx-6 overflow-x-auto lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-6 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3"
                        >
                          Key
                        </th>
                        <th
                          scope="col"
                          className="py-3.5 pl-6 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3"
                        >
                          Character Name
                        </th>
                        {/* <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Project Key
                    </th> */}

                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Bypass URL
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          URL
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Logo
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Video
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Text
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Animation
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900"
                        >
                          Idle Start Time
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900"
                        >
                          Idle End Time
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900"
                        >
                          Movement Start Time
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900"
                        >
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white">
                      {characters.map((character, index) => (
                        <tr
                          key={index}
                          className={
                            index % 2 === 0 ? undefined : "bg-gray-100"
                          }
                        >
                          <td className="whitespace-nowrap py-2 pl-6 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
                            {character.key || "-"}
                          </td>
                          <td className="whitespace-nowrap py-2 pl-6 pr-3 text-sm font-medium text-gray-500 sm:pl-3">
                            {character.characterName || "-"}
                          </td>
                          {/* <td className="whitespace-nowrap py-2 pl-6 pr-3 text-sm font-medium text-gray-500 sm:pl-3">
                        {character.projectKey}
                      </td> */}

                          <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                            https://main.d3krs3yccj5i4k.amplifyapp.com/avatar?key=
                            {character.key}
                          </td>
                          <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                            https://main.d3krs3yccj5i4k.amplifyapp.com/
                            {character.key}
                          </td>
                          <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                            <img
                              src={character.logo || "-"}
                              alt=""
                              className="h-10 w-10"
                            />
                          </td>
                          <td
                            className="whitespace-nowrap px-3 py-2 text-sm text-gray-500 cursor-pointer"
                            onClick={() =>
                              openVideoModal(character.video || "-")
                            }
                          >
                            {character.animation === "Custom" && (
                              <video
                                src={character.video || "-"}
                                className="h-10 w-10"
                              />
                            )}
                          </td>
                          <td className="whitespace-nowrap py-2 pl-6 pr-3 text-sm font-medium text-gray-500 sm:pl-3">
                            {character.text || "-"}
                          </td>
                          <td className="whitespace-nowrap py-2 pl-6 pr-3 text-sm font-medium text-gray-500 sm:pl-3">
                            {character.animation || "-"}
                          </td>
                          <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                            {character.animation === "Custom"
                              ? `${character.idleStartTime} sec`
                              : "-"}
                          </td>
                          <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                            {character.animation === "Custom"
                              ? `${character.idleEndTime} sec`
                              : "-"}
                          </td>
                          <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                            {character.movementStartTime &&
                            character.movementStartTime !== null &&
                            character.animation === "Custom"
                              ? JSON.parse(character.movementStartTime).map(
                                  (time, index, array) => (
                                    <span key={index}>
                                      {time} sec
                                      {index < array.length - 1 && ", "}
                                    </span>
                                  )
                                )
                              : "-"}
                          </td>

                          <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                            <div className="flex flex-row justify-around items-center">
                              <button
                                onClick={() => {
                                  setModalTitle("Update AI Character");
                                  setData(character);
                                  setModalOpen(!modalOpen);
                                }}
                              >
                                <PencilIcon className="h-6 w-6" />
                              </button>
                              <button
                                onClick={() => {
                                  handleDeleteCharacter(character.id);
                                }}
                              >
                                <TrashIcon className="h-6 w-6 text-red-500" />
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                <ReactModal
                  isOpen={videoModalOpen}
                  onRequestClose={() => setVideoModalOpen(false)}
                  contentLabel="Video Modal"
                >
                  <video
                    src={selectedVideo}
                    controls
                    className="w-full h-full"
                  />
                </ReactModal>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SoulMachinesTable;
